:root {
  --background-color-1: #ffa20044;
  --background-color-1-solid: #ffa200;
  --background-color-1-transparent: #ffa20088;
  --background-color-2: #de8e0299;
  --primary-color-1: #ffa200;
  --primary-color-2: #800202;
  --primary-color-2-transparent: #80020288;
  --primary-color-3: #1446a0;
  --primary-color-3-transparent: #1446a055;
  --primary-color-4: #cbd4c2;
  --primary-color-5: #fffcff;

  --warning-color-1: #800202;
  --success-color-1: #2a7221;

  --max-width: min(96vw, 600px);


}

#root {

  background-color: var(--background-color-1);
  max-width: 100vw;
  overflow-x: hidden;

}

.mainAppContainer {
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  background-color: var(--background-color-1);
  min-width: 100vw;
  min-height: 100vh;
}

.pageContainer {
  max-width: var(--max-width);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 2px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: var(--background-color-1)
}

.verticalSection {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

}

.verticalSectionTop {

  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

}

.horizontalSection {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;

}


.horizontalSectionLeft {

  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

}


.horizontalSectionCenter {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

}

.horizontalLine {
  margin-top: 6px;
  margin-bottom: 15px;
  height: 2px;
  width: 96%;
  background-color: var(--primary-color-2)
}

;


.App h2 {
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


/* Checkboxes */

/* Main */

.mainProgressCheckbox .ant-checkbox-inner {
  width: 35px !important;
  height: 35px !important;

}

.mainProgressCheckbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--success-color-1) !important;
  border-color: var(--success-color-1) !important;

}

.mainProgressCheckbox .ant-checkbox-inner::after {
  width: 12px;
  height: 17px;
  border-color: rgba(255, 255, 255, 1) !important;

}


.mainProgressCheckbox.disabled .ant-checkbox-inner {
  background-color: var(--primary-color-4) !important;
  border-color: 'black' !important;
}


.mainProgressCheckbox.disabled .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--success-color-1) !important;
  border-color: var(--success-color-1) !important;

}

/* Secondary */


.secondaryProgressCheckbox .ant-checkbox-inner {
  width: 30px !important;
  height: 30px !important;
  border-color: rgba(255, 255, 255, 1) !important;

}

.secondaryProgressCheckbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--success-color-1) !important;
  border-color: var(--success-color-1) !important;

}


.secondaryProgressCheckbox .ant-checkbox-inner::after {
  width: 10px;
  height: 16px;
  border-color: rgba(255, 255, 255, 1) !important;
}

.secondaryProgressCheckbox.disabled .ant-checkbox-inner {
  background-color: var(--primary-color-4) !important;
  border-color: 'black' !important;
}

.secondaryProgressCheckbox.disabled .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--success-color-1) !important;
  border-color: var(--success-color-1) !important;

}


/* Default */

.defaultProgressCheckbox .ant-checkbox-inner {
  width: 27px !important;
  height: 27px !important;
  border-color: rgba(255, 255, 255, 1) !important;

}


.defaultProgressCheckbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color-3) !important;
  border-color: var(--primary-color-3) !important;

}


.defaultProgressCheckbox .ant-checkbox-inner::after {
  width: 9px;
  height: 15px;
}

.defaultProgressCheckbox.disabled .ant-checkbox-inner {
  background-color: var(--primary-color-4) !important;
  border-color: 'black' !important;

}

.defaultProgressCheckbox.disabled .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color-3) !important;
  border-color: var(--primary-color-3) !important;

}

/* Multiple choice */


.multipleChoiceAnswerCheckbox .ant-checkbox-inner {
  width: 38px !important;
  height: 38px !important;
  border-color: rgba(255, 255, 255, 1) !important;

}


.multipleChoiceAnswerCheckbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color-3) !important;
  border-color: var(--primary-color-3) !important;

}


.multipleChoiceAnswerCheckbox .ant-checkbox-inner::after {
  width: 13px;
  height: 18px;
  border-color: rgba(255, 255, 255, 1) !important;
}

.multipleChoiceAnswerCheckbox.disabled .ant-checkbox-inner {
  background-color: var(--primary-color-4) !important;
  border-color: 'black' !important;

}

.multipleChoiceAnswerCheckbox.disabled .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color-3) !important;
  border-color: var(--primary-color-3) !important;

}


thead .ant-table-cell {
  background-color: var(--background-color-1) !important;
}

tbody .ant-table-cell {
  background-color: var(--background-color-1-transparent) !important;
}

.ant-popover-content {
  max-width: 80%;
}

.ant-radio {
  transform: scale(1.8);
}

.ant-radio+span {
  font-size: 2vh;
}